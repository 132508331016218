import React from 'react'
import './career_benefits.css'
const Career_benefits = () => {
    const customeEvent = () => {
  
        if (typeof window !== "undefined") {
        window.gtag('event', 'click', {
          'event_category': 'Career',
          'event_label': 'Mail'
        });
        
        }
      }
    return (
        <div className='career_benefits_wrapper'>
           <div className='container'>
               <div className='row'>
        <div className='col'>
        <h4 className='dropemail'>Drop Your CV at <a onClick={()=>customeEvent()}  href="mailto:hr@techvizon.com">hr@techvizon.com</a></h4> 
        </div>
               </div>
               </div> 
            <div className="features-clean">
        <div className="container">
            <div className="intro" data-aos-duration="1000" data-aos="fade-down">
                <h2 className="text-center">Benefits</h2>
                <p className="text-center">Live your best life !</p>
            </div>
            <div className="row features">
                
                <div  className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">Work From Anywhere</h3>
                    <p className="description">Not only during pandemic, but you can work from anywhere you like, from your home or your favourite hill station.</p>
                </div>
                <div className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">Flexible Work Hours</h3>
                    <p className="description">We don't want you to sit in office chairs for 9 hrs, if the work is done, you are free to go. No questions asked.</p>
                </div>
                <div className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">No Probation & Notice Periods</h3>
                    <p className="description">There's no probation period for anyone who works with us at Techvizon. Plus, we do not require you to serve notice periods.</p>
                </div>
                <div className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">Learning & Development</h3>
                    <p className="description">Work on latest technologies, we appreciate people who are technophiles.</p>
                </div>
                <div className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">Flexible Vacation Time</h3>
                    <p className="description">Unplugging from work is essential. And we push you to take vacation time.</p>
                </div>
                <div className="col-sm-6 col-lg-4 item fadeIn">
                    <h3 className="name">Equity</h3>
                    <p className="description">We believe equity policies should benefit you, not just the company. </p>
                </div>
                
            </div>
        </div>
    </div>

        </div>
    )
}

export default Career_benefits
