import React from 'react'
import Career from '../components/career/career'
import Career_benefits from '../components/career/career_benefits/career_benefits'
import Layout from "../components/layout"
import SEO from "../components/seo"
const CareerPage = () => (
    <Layout>
    <SEO title="Job Openings &amp; Career Opportunities at Techvizon" pathname="/career" description="Find all the job opportunities here &amp; join one of the best software development company &amp; get to work on cutting edge technologies." />
    <Career/>
    <Career_benefits/>
  </Layout>
)

export default CareerPage
