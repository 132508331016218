import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import './career.css'


const getCareerImage = graphql`
  {
    fluid: file(relativePath: { eq: "career.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
    `

const Career = () => {
    const data = useStaticQuery(getCareerImage);
    return (
        <div  className='career-wrap'>

            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 career-adjust mx-auto py-5'>
                <Img fluid={data.fluid.childImageSharp.fluid} />
                
                
                </div>
                </div>
              </div>
            
        </div>
    )
}

export default Career
